@use 'sass:list';

// override boostraps variables here

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
  xxl: 1800px
);

// import bootstrap
@import "bootstrap/scss/bootstrap";

// import glightbox
@import "npm:glightbox/dist/css/glightbox.css";

// pass in a map to define number of columns for each breakpoint
@mixin cols-per-breakpoint($params:()) {
    $gutter: 6px;

    @each $value in $params {
        @media (min-width: #{map-get($grid-breakpoints, list.nth($value, 1))}) {
            width: calc((100% / #{list.nth($value, 2)}) - #{$gutter});
        }
    }
}

@import "variables";
@import "embed";
@import "utils";
@import "message";
@import "writer";
@import "spinner";
@import "settings";
@import "login";
@import "navbar";
@import "dropdown";
@import "notification";
@import "search_results";

/* latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url("../assets/OpenSans-Regular-latin-extended.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url("../assets/OpenSans-Regular-latin.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@media (max-width: map-get($grid-breakpoints, "md")) {
    .container {
        padding: 0;
    }
}

.fa-icon {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
}

.contained-height {
    max-height: calc(100vh - 50px);
}

.content {
    position: relative;
    z-index: 0;
    margin-top: 1rem;
}

.material-shadow {
    box-shadow: $material-shadow;
}

.material-shadow-with-hover {
    box-shadow: $material-shadow;
    transition: all 0.2s ease-in-out;

    &:hover, &:focus {
        box-shadow: $material-shadow-hover;
    }
}

body {
    height: 100vh;
    overflow: hidden auto;
    background: #edebe9;
}

* {
    font-family: "Open Sans", sans-serif;
}

.progress-bar {
    position: absolute;
    bottom: 0;
    height: 1rem;
    width: 100%;
    background-color: lightgrey;

    div {
        height: 100%;
        background-color: $zusam_color;
        transition: 3s;
    }
}

.identite {
    .avatar {
        margin-top: 1rem;
    }

    form {
        border-left: 1px solid #ddd;
        padding-left: 1rem;
        margin-top: 1rem;
    }
}

.global-alert {
    position: fixed;
    width: 100%;
    text-align: center;
    bottom: 0;
    left: 0;
    z-index: 500;
    margin: 0;
}

.video {
    margin: 5px;
}

.card-body {
    padding: .45rem .65rem;
    overflow: hidden;
}

.removed-user {
    filter: grayscale(100);
}

.orange-error {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #f7a71b;
    font-size: 3rem;
    font-weight: bolder;
    text-shadow: 0 0 4px #444;
    transform: translate(-50%, -50%);
}

.group-name {
    text-align: center;
    color: #888;
    font-size: 1.2rem;
    padding: 10px 0;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;
    margin: 0 auto 10px;
    width: 90%;
    cursor: pointer;

    &:hover {
        font-weight: bold;
        color: #555;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.capitalize::first-letter {
    text-transform: capitalize;
}

.font-size-90 {
    font-size: 90%;
}

*:empty ~ .none-if-follows-empty {
    display: none;
}

.no-decoration, .no-decoration:hover {
    text-decoration: none;
}

.align-middle-inside {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.button-with-count {
    position: relative;
    font-size: 1.2rem;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.unread-badge {
    color: #fff;
    background: linear-gradient(0deg, #333, #777);
    border-radius: 100%;
    position: absolute;
    top: 10px;
    left: 50%;
    width: 14px;
    height: 14px;
}

.color-white {
    color: white;

    &:hover {
        color: white;
    }
}

.capitalize {
    text-transform: capitalize;
}
