.search-results-container {
    .message-preview-placeholder {
        display: block;
        width: 100%;
        margin: 1rem;
        background: linear-gradient(15deg, #999, #ddd);

        @media (max-width: map-get($grid-breakpoints, "sm")) {
            margin: 1rem 0;
        }
    }

    .message-preview {
        width: 100%;
        margin: 1rem;
        position: relative;

        @media (max-width: map-get($grid-breakpoints, "sm")) {
            margin: 1rem 0;
        }

        .card-body {
            max-height: 20rem;
            overflow-y: auto;
        }

        .card-miniature {
            width: 100px;
            height: 100px;
            background-size: cover;
            background-position: 50%;
            background-color: grey;
            overflow: hidden;
            float: left;
            margin-right: 15px;
        }

        .svg-miniature {
            width: 100%;
            height: 100px;
        }

        .text-preview {
            font-weight: normal;
            height: 180px;
            width: 100%;
            padding: 1rem;
            overflow: hidden;
            font-size: .9rem;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            word-wrap: break-word;

            &::first-letter {
                float: left;
                font-size: 6rem;
                color: #555;
                font-family: serif;
                margin-right: 10px;
            }
        }

        & > div {
            width: 100%;
            border: none;
            border-radius: 4px;

            .infos {
                display: flex;
                font-size: 90%;
                color: $gray-600;
                overflow: hidden;

                .dot {
                    top: 1px;
                    position: relative;
                }

                & > div {
                    margin-right: 5px;
                }
            }

            .children {
                font-size: 90%;
                color: $gray-600;
                text-align: right;
                position: sticky;
                top: 80px;
                margin-bottom: 5px;
            }
        }
    }
}
