@use 'sass:color';

.remove-button {
    display: none;
    cursor: pointer;
    top: 0;
    right: 0;
    position: absolute;
    padding: 7px 20px;
    font-size: 2rem;
    color: #fff;
    background: rgb(0 0 0 / 80%);
    border-bottom-left-radius: 10px;
}

.writer {
    background-color: white;
    padding: 10px;
    border-radius: 5px;

    .remove-button {
        display: block;
    }

    a.image {
        margin: 5px;
        position: relative;

        &.removed img {
            filter: grayscale(1) blur(2px);
        }
    }

    .title-input {
        width: 100%;
        padding: 10px;
        display: inline;
        border: none;
        border-bottom: 1px solid #eee;
    }

    .text-input {
        resize: none;
        min-height: 10rem;
        max-height: 40rem;
        width: 100%;
        padding: 10px;
        border: none;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
    }

    .options {
        padding: 5px 0;
        margin-top: 5px;
        display: flex;
        align-content: center;

        .option {
            background: none;
            border: none;
            margin: 0 5px;
            color: #888;
            font-size: 1.3rem;
            cursor: pointer;

            &:hover {
                color: #444;
            }
        }

        .actions {
            margin-left: auto;

            .cancel, .submit {
                border-radius: 2px;
                cursor: pointer;

                @media (hover: hover) {
                    &:hover {
                        background-color: color.adjust($zusam-color, $hue:-15deg);
                        border-color: color.adjust($zusam-color, $hue:-15deg);
                        color: white;
                    }
                }
            }

            .cancel {
                border-color: #f7a71b;
                color: #f7a71b;
                border: 1px solid;
                background-color: white;
                padding: 3px 9px;
            }

            .submit {
                padding: 4px 10px;
                margin-left: 2rem;
                background-color: #f7a71b;
                color: white;
                border: none;

                &:disabled {
                  background-color: #ffca6e;
                }
            }
        }
    }
}

.message.child .writer {
    width: 100%;
}

@media (min-width: map-get($grid-breakpoints, "md")) {
    .children .message .writer {
        margin-left: 5rem;
        margin-bottom: 1rem;
    }
}

@media (min-width: map-get($grid-breakpoints, "md")) {
    .message.child .writer {
        position: relative;
        top: 12px;
    }
}

.writer .video-uploaded .spinner {
    display: none;
}

.write-button {
    background-color: $zusam-color;
    border-radius: 100%;
    bottom: 2rem;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    height: 4rem;
    justify-content: center;
    padding: 19px;
    position: fixed;
    right: 2rem;
    width: 4rem;
    z-index: 500;
    color: #fff;

    &:hover {
        background-color: color.adjust($zusam-color, $hue:-15deg);
        color: #fff;
    }
}
