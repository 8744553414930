.embed-preview {
    display: inline-block;
    max-width: 100%;
    height: 270px;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 3px #444;

    *::before, *::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
    }

    *::before {
        z-index: 1;
    }

    *::after {
        border-style: solid;
        border-color: transparent transparent transparent #fff;
        z-index: 2;
    }

    img {
        transform: translate(-50%, -50%);
        position: relative;
        left: 50%;
        top: 135px; // not 50% because it doesn't work in chromium
        min-width: 270px;
    }

    .generic::before {
      background: #212121;
      height: 80px;
      width: 80px;
      margin-left: -43px;
      margin-top: -40px;
      opacity: .8;
      border-radius: 20px;
      transition: all .25s;
    }

    &:hover > .generic::before {
      background: #000;
    }

    .generic::after {
      border-width: 16px 0 16px 30px;
      margin: -17px 0 0 -15px;
      transition: all .25s;
    }

    .lichess::before {
        background: #212121;
        height: 68px;
        width: 68px;
        margin-left: -34px;
        margin-top: -34px;
        opacity: .8;
        transition: .8s;
    }

    &:hover > .lichess::before {
        opacity: 1;
        background: #000;
    }

    .lichess::after {
        border-width: 12px;
        margin: -12px 0 0 -12px;
        border-color: #fff;
        transform: rotate(45deg);
        transition: .8s;
    }

    &:hover > .lichess::after {
        transform: rotate(360deg);
        border-radius: 100%;
        transition: .8s;
    }

    .youtube::before {
        background: #212121;
        height: 48px;
        width: 68px;
        margin-left: -34px;
        margin-top: -24px;
        border-radius: 10px;
        opacity: .8;
        transition: all .25s;
    }

    &:hover > .youtube::before {
        opacity: 1;
        background: #f00;
    }

    .youtube::after {
        border-width: 10px 0 10px 20px;
        margin: -10px 0 0 -8px;
    }

    .gamerdvr::before {
        background: #008cba;
        height: 68px;
        width: 68px;
        margin-left: -35px;
        margin-top: -34px;
        border-radius: 100%;
    }

    &:hover > .gamerdvr::before {
        background: #2e9922;
    }

    .gamerdvr::after {
        border-width: 10px 0 10px 20px;
        margin: -10px 0 0 -8px;
    }

    .twitch::before {
        background: #212121;
        height: 100%;
        width: 100%;
        opacity: .8;
        position: absolute;
        top: 0;
        left: 0;
    }

    .twitch::after {
        border-width: 20px 0 20px 35px;
        margin: -20px 0 0 -17px;
        transition: all .25s;
    }

    &:hover > .twitch::after {
        border-color: transparent transparent transparent #a991d4 !important;
    }

    .soundcloud::before {
        background: #f60;
        height: 50px;
        width: 50px;
        margin-left: -25px;
        margin-top: -25px;
        border-radius: 100%;
        opacity: .95;
        transition: all .25s;
    }

    &:hover > .soundcloud::before {
        background: #f70;
        opacity: 1;
    }

    .soundcloud::after {
        border-width: 8px 0 8px 12px;
        margin: -8px 0 0 -4px;
    }

    .bandcamp::before {
        background: #fff;
        height: 50px;
        width: 54px;
        margin-left: -34px;
        margin-top: -24px;
        border-radius: 3px;
        transition: all .25s;
        box-shadow: 0 0 4px #444;
    }

    .bandcamp::after {
        border-color: transparent transparent transparent #000;
        border-width: 10px 0 10px 20px;
        margin: -9px 0 0 -16px;
    }
}

.file-embed {
  &.drag-over {
    border: 5px dashed black;
  }

  display: block;
  background-size: auto;
  text-decoration: none;
  background-color: #ececec;

  &.file-loading {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    animation: 1s shine linear infinite;
    background-size: 200% 100%;
  }
}

.embed-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
        max-width: 100%;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0;
    padding: 0;
    overflow: hidden;
    background: whitesmoke;
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

.embed-responsive-1by1 {
    padding-bottom: 100%;
}

.embed-responsive-item {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.bandcamp-embed {
    @media (min-width: map-get($grid-breakpoints, "md")) {
        width: 450px;
        height: 570px;
        padding: 0 !important;
        margin: 0 auto;
    }
}
